import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_media = _resolveDirective("media")!

  return _withDirectives((_openBlock(), _createElementBlock("img", {
    class: _normalizeClass(["rounded-circle", _ctx.styleClass]),
    src: "",
    alt: "",
    ref: "avatar"
  }, null, 2)), [
    [_directive_media, {
      url: _ctx.avatarUrl,
      noFetch: !_ctx.hasId,
      fail: _ctx.handleError,
      auth: true,
      httpCache: true,
    }]
  ])
}