
import { Options, Prop, Vue } from "vue-property-decorator";
import Image from "@/directives/Image";
// @ts-ignore
import instructorPlaceholder from "@/assets/teacherimgplaceholder.png";

@Options({
  directives: {
    Media: new Image(),
  },
})
export default class Avatar extends Vue {
  public name = "Avatar";

  @Prop({ type: Number, default: 0 })
  public userId!: number;

  @Prop({ type: String, default: "avatar-lg" })
  public styleClass!: number;

  private get avatarUrl(): string {
    return `students/current/avatar?id=${this.userId}`;
  }

  private handleError(): any {
    const avatarEl = this.$refs.avatar as HTMLImageElement;
    avatarEl.src = instructorPlaceholder;
    avatarEl.alt = "Profile";
  }

  private get hasId(): boolean {
    return Boolean(this.userId);
  }
}
