import { Options, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";

const StudentDocumentModule = namespace("student-document");
@Options({})
export default class StudentDocumentsStoreMixin extends Vue {
  @StudentDocumentModule.Action("findAll")
  public findStudentDocumentAction: any;

  @StudentDocumentModule.Getter("getDataList")
  public docs: any;

  @StudentDocumentModule.Getter("getUnseenCount")
  public unseenCount: any;

  @StudentDocumentModule.Getter("getIsLoading")
  public getLoadingDocs: any;

  public mountedLoading = false;

  public get loadingDocs() {
    return this.mountedLoading && this.getLoadingDocs;
  }

  protected async loadStudentDocuments(): Promise<void> {
    await this.findStudentDocumentAction({
      resource: "student-documents/student/current",
      hideSuccessMessage: true,
    });
    this.mountedLoading = false;
  }
}
