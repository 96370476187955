
import { Options, Prop, Vue } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import { isLivePayEnabled } from "@/helper/LivePayHelper";
import { namespace } from "s-vuex-class";

const LivePayMandateModule = namespace("live-pay-mandate");

@Options({
  components: { EducationTabs },
})
export default class AccountImg extends Vue {
  @Prop()
  public studentEducation!: any;
  @Prop({ default: 0 })
  public balance!: any;

  @Prop({ default: () => false })
  public basicView!: boolean;

  @LivePayMandateModule.Getter("getDataItem")
  public isLivePayMandateSetUp: any;

  private get studentEducationX(): number {
    if (!this.studentEducation || !this.studentEducation.mainLicenseClass) {
      return -3;
    }
    if (this.studentEducation.mainLicenseClass.length === 2) {
      return -3;
    }
    return 8.5;
  }

  protected isLoadAccountEnabled(selectedEducation: any): boolean {
    return isLivePayEnabled(selectedEducation) && this.isLivePayMandateSetUp;
  }

  private showLoadAccountForm(): void {
    if (isLivePayEnabled(this.studentEducation)) {
      this.$emit("onShowLoadAccountForm");
    }
  }
}
