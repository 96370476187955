
import { Options, Prop, Vue } from "vue-property-decorator";
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonMenuButton, IonButton } from "@ionic/vue";
import DriveBuzzLogo from "@/components/DriveBuzzLogo.vue";

@Options({
  components: {
    DriveBuzzLogo,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonMenuButton,
    IonTitle,
    IonButton,
  },
})
export default class DefaultHeader extends Vue {
  public name = "DefaultHeader";

  @Prop({ type: Boolean, default: () => false })
  public showBackButton!: boolean;

  @Prop({ type: Function, default: () => null })
  public customBackButtonFunc!: any;

  @Prop({ type: Boolean, default: () => false })
  public useCustomFunc!: any;

  public onClickBack(): void {
    if (this.useCustomFunc) {
      this.customBackButtonFunc();
    } else {
      this.$router.go(-1);
    }
  }
}
