
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import { IonPage, IonRow, IonCol, IonContent, IonGrid, IonRefresher, IonRefresherContent, isPlatform } from "@ionic/vue";
import Navigation from "@/components/Layout/Navigation/Navigation.vue";
import DriveBuzzLogo from "@/components/DriveBuzzLogo.vue";
import TopBarDesk from "@/components/Layout/Top/TopBarDesk.vue";
import NewsBar from "@/components/Layout/NewsBar/NewsBar.vue";
import DefaultHeader from "@/components/Headers/DefaultHeader.vue";
import emitter from "@/utils/EventBus.ts";

const GlobalMessageModule = namespace("global-message");

@Options({
  components: {
    DefaultHeader,
    NewsBar,
    TopBarDesk,
    Navigation,
    DriveBuzzLogo,
    IonPage,
    IonRow,
    IonCol,
    IonContent,
    IonGrid,
    IonRefresher,
    IonRefresherContent,
  },
})
export default class DefaultLayout extends Vue {
  public name = "DefaultLayout";

  @Prop({ type: Boolean, default: () => false })
  public safeArea!: boolean;

  @Prop({ type: Boolean, default: () => true })
  public scrollY!: boolean;

  @GlobalMessageModule.Getter("getMessage")
  protected globalMessage: any;

  @GlobalMessageModule.Action("resetMessages")
  protected resetGlobalMessageAction: any;

  @Prop({ type: Boolean, default: () => false })
  public showBackButton!: boolean;

  @Prop({ type: Function, default: () => null })
  public customBackButtonFunc!: any;

  @Prop({ type: Boolean, default: () => false })
  public useCustomFunc!: any;

  @Prop({ type: String, default: () => "" })
  public contentClass!: any;

  private refreshKey = 0;

  @Watch("globalMessage", { immediate: true, deep: true })
  private onGlobalMessageChange(globalMessage: any): void {
    if (globalMessage) {
      switch (globalMessage.type) {
        case "error": {
          const errorMessage = globalMessage.text ? globalMessage.text : this.$t("messages.loading_error");
          this.$toasted.error(errorMessage);
          break;
        }
        case "success":
          this.$toasted.success(globalMessage.text);
          break;
        default:
          this.$toasted.show(globalMessage.text);
          break;
      }
      this.resetGlobalMessageAction();
    }
  }

  public doRefresh(event: any): void {
    setTimeout(() => {
      this.refreshKey++;
      event.target.complete();
      emitter.$emit("page-refresh");
    }, 1000);
  }

  public get isDesktop(): boolean {
    return isPlatform("desktop");
  }
}
