
import { Options, Vue } from "vue-property-decorator";
import Avatar from "@/components/Avatars/Avatar.vue";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";
import { getBuildInfo } from "vue-cli-plugin-build-info/plugin";
import moment from "moment/moment";

@Options({
  components: { Avatar },
})
export default class Version extends Vue {
  protected backendVersionResponse: { version: string; time: string } | null = null;
  public mounted(): void {
    this.getBackendVersion();
  }

  public async getBackendVersion(): Promise<void> {
    if (process.env.VUE_APP_ENV != "development") return;
    const url = process.env.VUE_APP_BACKEND_BASE_URL + "/actuator/info";

    return await axios
      .get(url)
      .then((response: AxiosResponse) => {
        this.backendVersionResponse = response.data.build;
      })
      .catch((error: AxiosError) => {
        console.log("version fetch error");
      });
  }

  public get backendVersion(): string {
    if (!this.backendVersionResponse) return "";
    return `backend ${this.backendVersionResponse.version}`;
  }

  public get backendTime(): string {
    if (!this.backendVersionResponse) return "";
    return `${this.formatDateTime(this.backendVersionResponse.time)}`;
  }
  public formatDateTime(date: string): string {
    return moment(date).format("HH:mm DD.MM.YYYY");
  }

  public get frontendVersion(): string {
    const { VERSION } = getBuildInfo();
    return `frontend ${VERSION}`;
  }

  public get frontendTime(): string {
    const { TIMESTAMP } = getBuildInfo();
    return `${this.formatDateTime(TIMESTAMP)}`;
  }
}
