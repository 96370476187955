import StorageService from "@/services/StorageService";

export default () => {
  const signOut = (): void => {
    sessionStorage.removeItem("vuex");
    StorageService.removeFromStorage("token");
    StorageService.removeFromStorage("tokenInfo");
    StorageService.removeFromStorage("user");
    StorageService.removeFromStorage("remember");
    location.reload();
  };
  return {
    signOut,
  };
};
